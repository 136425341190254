import React from 'react'
import {
  Layout,
  Navbar,
  Box,
  Container,
  Text,
  Flex,
  Image,
  Link,
  Footer,
  Reveal,
  Accordion,
} from '../components'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { HIDE_SALARIES } from '../utils/configs'

// Styled components

const TitleWrapper = styled(Box)`
  padding: 1em 0em;
  border-bottom: 1px solid ${props => props.theme.colors.lightPurple};
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;

  padding-left: 3em;
`

const Li = styled.li`
  margin-top: 0.5em;
  color: ${props => props.theme.colors.white};
`

const SectionTitle = styled(Text)`
  color: ${props => props.theme.colors.white};
  font-size: 1.3em;
  margin-top: 1em;
`

const SectionItem = styled(Text)`
  color: ${props => props.theme.colors.silver};
  font-size: 1.3em;
  vertical-align: text-top;
`

// Main component

export const query = graphql`
  query {
    topBackgroundImage: file(relativePath: { eq: "top_background.jpg" }) {
      ...getImage
    }

    imageCareers: file(relativePath: { eq: "image-careers.jpg" }) {
      ...getImage
    }

    allJobsYaml {
      edges {
        node {
          key
          title
          salary
          prerequisites
          responsibilities
          benefits
        }
      }
    }
  }
`

interface IJobItem {
  node: {
    key: number
    title: string
    salary: Array<string>
    prerequisites: Array<string>
    responsibilities: Array<string>
    benefits: Array<string>
  }
}

interface ICareersPageProps {
  data: {
    topBackgroundImage: any
    imageCareers: any

    allJobsYaml: {
      edges: Array<IJobItem>
    }
  }
}

const CareersPage: React.SFC<ICareersPageProps> = props => {
  const sortedItems = props.data.allJobsYaml.edges.sort(
    (a, b) => a.node.key - b.node.key,
  )

  const showSalaries = !HIDE_SALARIES

  return (
    <Layout
      backgroundImage={`url(${props.data.topBackgroundImage.publicURL}), url(${props.data.topBackgroundImage.publicURL})`}
      backgroundSize='80% auto, 80% auto'
      backgroundPosition='top center, bottom center'
    >
      <>
        <Navbar />

        <Container>
          <Box padding='3em 0em 0em 0em'>
            <Reveal>
              <Image
                src={props.data.imageCareers.publicURL}
                radius='0.2em'
                style={{ width: '100%' }}
                tilt
              />
            </Reveal>
          </Box>

          <Box padding='5em 0em 0em 0em'>
            <Reveal>
              <>
                <Box>
                  <Text color='white' weight={300} size='2.5em'>
                    We are hiring!
                  </Text>
                </Box>

                <Box padding='0.7em 0em 0em 0em'>
                  <Text
                    color='white'
                    weight={300}
                    size='1.5em'
                    lineHeight={1.5}
                  >
                    Start working with our fantastic team in a first-class
                    remote environment, because your home is our best office.
                    Check out the positions we are offering:
                  </Text>
                </Box>
              </>
            </Reveal>
          </Box>

          <Box padding='4em 0em 0em 0em'>
            <Reveal>
              <Accordion
                items={sortedItems}
                renderTitle={(item, index, isOpen) => (
                  <TitleWrapper
                    style={{
                      marginTop: index !== 0 ? '1.5em' : undefined,
                      opacity: isOpen ? 1 : 0.7,
                    }}
                  >
                    <Text color='lightPurple' size='1.5em'>
                      {item.node.title}
                    </Text>
                  </TitleWrapper>
                )}
                renderContent={item => (
                  <>
                    {showSalaries ? (
                      <>
                        <Box>
                          <SectionTitle>Salary range:</SectionTitle>
                        </Box>

                        <Box padding='0.5em 0em 0em 0em'>
                          <SectionItem>
                            {`${item.node.salary[0]} - ${item.node.salary[1]}`}
                          </SectionItem>
                        </Box>
                      </>
                    ) : undefined}

                    <Box>
                      <SectionTitle>Prerequisites:</SectionTitle>
                    </Box>

                    <Ul>
                      {item.node.prerequisites.map(
                        (prerequisite, prerequisiteIndex) => (
                          <Li key={`prerequisite${prerequisiteIndex}`}>
                            <SectionItem>{prerequisite}</SectionItem>
                          </Li>
                        ),
                      )}
                    </Ul>

                    <Box>
                      <SectionTitle>Responsibilities:</SectionTitle>
                    </Box>

                    <Ul>
                      {item.node.responsibilities.map(
                        (responsibility, responsibilityIndex) => (
                          <Li key={`prerequisite${responsibilityIndex}`}>
                            <SectionItem>{responsibility}</SectionItem>
                          </Li>
                        ),
                      )}
                    </Ul>

                    <Box>
                      <SectionTitle>Benefits:</SectionTitle>
                    </Box>

                    <Ul>
                      {item.node.benefits.map((benefit, benefitIndex) => (
                        <Li key={`prerequisite${benefitIndex}`}>
                          <SectionItem>{benefit}</SectionItem>
                        </Li>
                      ))}
                    </Ul>

                    <Box padding='1.5em 0em 0em 0em'>
                      <a href='mailto:jobs@efficiam.com'>
                        <Text color='lightPurple' size='1.2em' underline>
                          Don't hesitate to apply here!
                        </Text>
                      </a>
                    </Box>
                  </>
                )}
                keyExtractor={item => `${item.node.key}`}
              />
            </Reveal>
          </Box>

          <Box padding='12em 0em 8em 0em'>
            <Reveal>
              <Footer />
            </Reveal>
          </Box>
        </Container>
      </>
    </Layout>
  )
}

export default CareersPage
